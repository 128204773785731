$primaryBackground: --primaryBackground;
$secondryBackground: --secondryBackground;
$tertiaryBackground: --tertiaryBackground;
$quaternaryBackground: --quaternaryBackground;
$quinaryBackgroud: --quinaryBackgroud;
$whitesmokeBackground: --whitesmokeBackground;
$primaryBackgroundCard: --primaryBackgroundCard;
$primaryColor: --primaryColor;
$secondaryColor: --secondaryColor;
$errorTextColor: --errorTextColor;
$primaryTextColor: --primaryTextColor;
$primaryBorder: --primaryBorder;
$primaryButtonColor: --primaryButtonColor;
$borderColor: --borderColor;
$boxShadow: --boxShadow;
$errorColor: --errorColor;
$infoColor: --infoColor;
$warningColor: --warningColor;
$disableColor: --disableColor;
$placeholderColor: --placeholderColor;
$dateColor: --dateColor;
$secondryFont: "Nunito Sans", sans-serif;
$primaryFont: Matter, ui-sans-serif, system-ui, -apple-system,
  BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
  sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
  Noto Color Emoji;
$backdropShadow: --backdropShadow;
$primaryBoxShadowColor: --primaryBoxShadowColor;
$primaryButtonBorder: --primaryButtonBorder;
$secondayButtonBorder: --secondayButtonBorder;
$disableButtonColor: --disableButtonColor;
$searchInputColor: --searchInputColor;
$searchInpurtBorderColor: --searchInpurtBorderColor;
$suggestionItemBorderColor: --suggestionItemBorderColor;
$clearButtonColor: --clearButtonColor;
$selectAddressContainer: --selectAddressContainer;
$selectAddressContainerBorder: --selectAddressContainerBorder;
$confirmAddressBackgroundColor: --confirmAddressBackgroundColor;
$softGrayGradient : --softGrayGradient;
$naturalToneGradient:--naturalToneGradient;
$selectAddressColor:--selectAddressColor;
$addressDrawerBackground: --addressDrawerBackground;
$cartImageBackground:--cartImageBackground;
$discountColor:--discountColor;
$discountBackgroundColor: --discountBackgroundColor;
$unitQuanityColor: --unitQuanityColor;
// Light theme
$theme-map-light: (
  $primaryBackground: #27ae60,
  $primaryTextColor: #11a44e,
  $secondryBackground: #e7f7ed,
  $tertiaryBackground: #bee7cf,
  $quaternaryBackground: #ffffff,
  $whitesmokeBackground: #f5f5f5,
  $quinaryBackgroud: #f2994a,
  $primaryColor: #000000,
  $secondaryColor: #ffff,
  $primaryBackgroundCard: #e9f7ef,
  $errorTextColor: rgba(128, 128, 128, 0.497),
  $primaryBorder: #69686845,
  $primaryButtonColor: #e7e9ea2b,
  $borderColor: #cacaca,
  $boxShadow: rgba(0, 0, 0, 0.1),
  $errorColor: rgb(233, 41, 41),
  $infoColor: #d9edf7,
  $warningColor: #fcf8e3,
  $disableColor: #adadad,
  $placeholderColor: #c7bfbf,
  $dateColor: grey,
  $primaryBoxShadowColor: rgba(0, 0, 0, 0.2),
  $primaryButtonBorder: #007bff,
  $secondayButtonBorder: #0056b3,
  $backdropShadow: rgba(0, 0, 0, 0.5),
  $disableButtonColor: #e6f7ff,
  $searchInputColor: rgb(79, 79, 79),
  $searchInpurtBorderColor: #ccc,
  $suggestionItemBorderColor: #f1f1f1,
  $clearButtonColor:#666,
  $selectAddressContainer:rgb(246, 252, 252),
  $selectAddressContainerBorder:rgb(232, 232, 232),
  $confirmAddressBackgroundColor: #f9f9f9,
  $softGrayGradient:#f0f0f0,
  $naturalToneGradient:#e0e0e0,
  $selectAddressColor:rgb(130, 130, 130),
  $addressDrawerBackground:rgb(244, 246, 252),
  $cartImageBackground:rgb(248, 248, 248),
  $discountColor: #256FEF,
  $discountBackgroundColor:rgb(219, 232, 255),
  $unitQuanityColor:#696969,

);

// Dark theme
$theme-map-dark: (
  $primaryBackground: rgb(30, 30, 30),
  $secondryBackground: #585858,
  $primaryBackgroundCard: #e9f7ef,
  $dateColor: white,
  $primaryBoxShadowColor: rgba(0, 0, 0, 0.2),
  $primaryButtonBorder: #007bff,
  $secondayButtonBorder: #0056b3,
  backdropShadow: rgba(0, 0, 0, 0.5),
  $disableButtonColor: #e6f7ff,
);

@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}
