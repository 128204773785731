.success-container {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f0f9f0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.success-message h1 {
  color: #28a745;
  font-size: 24px;
  margin-bottom: 10px;
}

.success-message p {
  color: #555;
  line-height: 1.6;
}

.support-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
}

.support-link:hover {
  background-color: #0056b3;
}
