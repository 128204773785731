.address-drawer-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-height: 50vh;
  height: fit-content;
  justify-content: center;
 
}
.address-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-height: 300px;
  overflow-x: scroll;
  padding-bottom: 16px;
}
 .current-address-container{
  display: flex;
  align-items: start;
  gap: 8px;
  
  color: var($primaryBackground);
  margin-left: -3px;
  padding-bottom: 10px;
}

.address-list-top-shadow {
  box-shadow: inset 0 10px 10px -10px 0 2px 8px var($primaryBoxShadowColor);
}

.address-list-bottom-shadow {
  box-shadow: inset 0 -10px 10px -10px 0 2px 8px var($primaryBoxShadowColor);
}
.address-list-top-shadow.address-list-bottom-shadow {
  box-shadow: inset 0 10px 10px -10px 0 2px 8px var($primaryBoxShadowColor),
    inset 0 -10px 10px -10px 0 2px 8px var($primaryBoxShadowColor);
}
.address-drawer-checkbox-with-text {
  display: flex;
  align-items: start;
  gap: 10px;
}

.address-drawer-checkbox {
  flex-shrink: 0;
  margin-top: 5px;
}

.address-drawer-text-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  gap: 4px;
}

.address-drawer-primary-text {
  font-size: 16px;
  font-weight: 500;
  color: var($primaryColor) !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.address-drawer-secondary-text {
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px;
  width: 320px;
}

.view-location-container {
  text-decoration: none;
  display: flex;
  color: var($primaryColor);
}
.add-new-address{
  padding: 10px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 12px;
  font-size: 15px;
  font-weight: 600;
  color: var($primaryBackground);
  background: var($secondaryColor);
  border-radius: 12px;
  margin: 12px 0px;
  margin-top: 0;
  cursor: pointer;
}
.add-new-address-plus{
  font-size: 20px;
  font-weight: 900;
  padding-bottom: 2px;
}
.location-enable-container{
  padding: 10px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-between;
  background: var($secondaryColor);
  border-radius: 12px;
  margin-bottom: 10px;
}
.location-enable{
  display: flex;
  align-items: center;
  gap: 10px;
}
.location-enable-button{
  padding: 5px 14px;
  background-color: var($primaryBackground);
  color: var($secondaryColor);
  border-radius: 8px;
  font-size: 14px;
}
.location-enable-image{
  height: 30px;
  width: 30px;
}
.location-enable-heading{
  font-size: 14px;
  font-weight: 700;
}
.location-enable-text{
  font-size: 12px;
}
.current-address-container-img{
  height: 14px;
  margin-top: 3px;
  margin-left: 3px;
  
}
.current-address-text{
  font-size: 14px;
  font-weight: 700;
}