.error-handler {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 72vh;
  background-color: var($quaternaryBackground);
  .error-handler-image {
    margin-top: 5vh;
    width: 100%;
    height: 100%;
    max-width: 350px;
    max-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    .error-image {
      width: 70%;
      height: 70%;
    }
  }

  .error-status-code {
    position: absolute;
    top: 25%;
    left: 10%;
    font-size: 10rem;
    transform: rotate(-10deg);
    font-weight: 600;
    opacity: 0.2;
  }
  .error-handler-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 350px;
    height: 400px;
    background-color: var($quaternaryBackground);
  }

  .error-handler-title {
    font-size: 24px;
    font-weight: 600;
  }

  .error-handler-description {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
  }

  .error-handler-button {
    width: 100px;
    height: 60px;
    margin-top: 20px;
    color: var($quaternaryBackground);
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
  .back-to-home {
    margin-top: 10px;
    color: var($primaryButtonColor);
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
  }
  .btn {
    margin-top: 20px;
    padding: 10px 30px;
    color: var($quaternaryBackground);
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
  .btn-primary {
    background-color: var($primaryButtonColor);
    color: var($quaternaryBackground);
  }
}
#webpack-dev-server-client-overlay {
  display: none;
}
