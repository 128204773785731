.buttonLoader {
  border: 2px solid var($primaryColor);
  border-left-color: transparent;
  width:  100%;
  height:  100%;
  border-radius: 50%;
  animation: buttonLoader 1s linear infinite;
}

@keyframes buttonLoader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}