.tab-bar-container {
    width: 100%;
    height: 74px;            
    box-sizing: border-box;
}
  
.tab-bar {
    display: flex;
    align-items: center;
    padding-top: 8px;
    overflow-x: auto;
    scrollbar-width: none;
}
  
.tab-item {
    padding: 3px 15px;
    font-size: 14px;
    cursor: pointer;
    color: var($secondaryColor);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.tab-icon{
    width: 24px; 
    height: 24px;
    object-fit: contain;
    color: var($secondaryColor);
    filter: invert(1) brightness(2);
}
.active-tab {
    border-bottom: 3px solid var($primaryColor);
    color: var($primaryColor);
    font-weight: bold;
}
.tab-item.active-tab .tab-icon {
    filter: invert(0) brightness(0) drop-shadow(0px 0px 0px var($primaryColor)) ;
}