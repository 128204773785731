.track-order-card {
  margin-bottom: 10px;
  width: 82vw;
  display: flex;
  .track-order-card-time-container {
    width: 18vw;
    text-align: center;
    .track-order-card-time {
      font-size: 15px;
      font-weight: 600;
    }
    .track-order-card-date {
      font-size: 13px;
      font-weight: 500;
      color: var($dateColor);
    }
  }
  .track-order-card-check {
    width: 20vw;
    height: 12vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    .order-status {
      width: 33px;
      height: 33px;
      border-radius: 50%;
    }
    .order-done {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var($primaryBackground);
      color: var($secondaryColor);
      font-size: 13px;
    }
    .order-ongoing {
      background-color: var($secondaryColor);
      border: 1px solid var($borderColor);
    }
    .track-order-spacing-line {
      width: 1px;
      height: 6vh;
      background-color: rgb(219, 211, 211);
    }
  }
  .delivery-status {
    justify-content: start;
    height: 5vh;
  }
  .track-order-card-status-container {
    width: 44vw;
    display: flex;
    flex-direction: column;
    .track-order-status-heading {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .track-order-status-content {
      color: grey;
      font-size: 16px;
    }
  }
}
