.product-dropdown-card {
    width: 100%;
    padding: 2px 12px;
    text-decoration: none;

    .product-dropdown-card-main {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 15px;

        .product-dropdown-card-image-cover {
            height: 50px;
            width: 50px;
            padding: 5px;

            .product-dropdown-card-image{
                height: 100%;
                width: 100%;
                border-radius: 10px;
                border: 1px solid var($primaryTextColor);
            }
        }
        .product-dropdown-card-info {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            
            .product-dropdown-card-name {
                font-size: 17px;
                font-weight: 600;
                color: var($primaryColor);
                text-align: center;
            }
        }
    }
}