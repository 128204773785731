.add-to-cart-button {
  width: 66px;
  height: 31px;
  background-color: var($primaryBackground);
  border-radius: 0.375rem;
  border: 1px solid var($primaryBackground);
  color: var($secondaryColor);
  cursor: pointer;
  font-weight: 600;
  padding: 0 8px;
  margin-top: -20px;
}
.add-to-cart-btn-container {
  cursor: pointer;
    width: 66px;
    border: 1px solid var($primaryBackground);
    height: 31px;
    font-weight: 600;
    font-size: 13px;
    margin-top: -20px;
    border-radius: 0.375rem;
    display: flex;
    padding: 0 8px;
    -webkit-box-align: center;
    align-items: center;
    background-color: var($primaryBackground);
    color: var($secondaryColor);
    -webkit-box-pack: justify;
    justify-content: space-between;
  .add-to-cart-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  .add-to-cart-input {
    cursor: pointer;
    width: 20px;
    border: none;
    color: var($secondaryColor);
    text-align: center;
    background-color: transparent;
    outline: none;
  }
  .substract-btn {
     font-size: 18px;
     font-weight: 600;
    color: var($secondaryColor);
  }
  .add-to-cart-btn{
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 2px;
    color: var($secondaryColor);
  }
}
