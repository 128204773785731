.products-container {
  width: 93vw;
  border-radius: 30px;
  background-color: var(--quaternaryBackground); 
  padding: 3px; 
  display: grid !important; 
  grid-template-columns: repeat(3, 2fr) !important;
  gap: 12px; 
  justify-items: start;
}
.p-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 30px;
  background-color: var($quaternaryBackground);
  margin: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.p-container:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}
.brand_name {
  font-size: 1.5rem;
  font-weight: 600;
  color: var($primaryColor);
  margin-bottom: 5px;
  text-align: center;
  margin: 3vmax 0vmax;
  border-bottom: 1px solid var($primaryColor);
  padding: 1px;
}


.popular-accordian {
  overflow-x: scroll;
  overflow-y: hidden;
  background-color: var($secondaryColor);
  .category-accordian__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1rem 0;
  }
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 15px;
}


.category-accordian {
  overflow-x: scroll;
  overflow-y: hidden;
  background-color: var($secondaryColor);
  .category-accordian__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1rem 0;
  }
  padding: 10px 10px 0px 10px;
  margin-bottom: 15px;
  border-radius: 15px;
}
.brand-accordian {
  overflow-x: scroll;
  overflow-y: hidden;
  background-color: var($secondaryColor);
  .brand-accordian__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1rem 0;
  }
  padding: 10px;
  border-radius: 15px;
}
.spinner-container-main {
  width: 100vh;
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
