.confirm-address-container {
  position: absolute;
  bottom: 0;
  z-index: 999;
  height: auto;
  width: 100%;
  padding: 12px 12px 24px;
  border: 1px solid var(--searchInpurtBorderColor);
  border-radius: 8px 8px 0 0;
  background-color: var(--confirmAddressBackgroundColor);

  .confirm-address-container-h3 {
    color: var(--primaryColor);
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .selected-address-container {
    display: flex;
    padding: 8px 12px 8px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    border: 0.5px solid var(--selectAddressContainerBorder);
    background: var(--selectAddressContainer);

    .selected-address {
      color: var(--selectAddressColor);
      font-size: 14px;
      font-weight: 400;
    }
  }

  .landmark-container {
    margin-top: 10px;

    .landmark-label {
      display: block;
      font-size: 14px;
      font-weight: 500;
      color: var(--secondaryTextColor);
      margin-bottom: 6px;
    }

    .landmark-input {
      width: 100%;
      padding: 10px 12px;
      font-size: 14px;
      color: var(--selectAddressColor);
       border: 0.5px solid var(--selectAddressContainerBorder);
      border-radius: 8px;
      background-color: var(--inputBackgroundColor);
      outline: none;
      transition: border-color 0.3s;

      &:focus {
        border-color: var(--inputFocusBorderColor);
      }
    }
  }

  .shimmer-content {
    display: flex;
    gap: 15px;
    align-items: flex-start;
  }

  .shimmer-image {
    width: 48px;
    height: 48px;
  }

  .shimmer-address {
    width: 100%;
    height: 48px;
  }

  .shimmer-button {
    width: 100%;
    height: 45px;
    margin-top: 10px;
    border-radius: 8px;
  }

  .confirm-button {
    margin-top: 10px;
    padding: 12px 0;
    background-color: var(--primaryBackground);
    border-radius: 8px;
    color: var(--secondaryColor);
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
  }
}


