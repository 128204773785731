.orders-container {
  display: flex;
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 40px 40px 0 0;
  width: 100%;
  height: 70vh;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: var($quaternaryBackground);
  padding-top: 15px;
  .order-status {
    position: sticky;
    top: 0;
    width: 100%;
    height: 5vh;
    overflow-x: scroll;

    display: flex;
    .status-category {
      margin-left: 10px;
      padding: 10px;
      border-radius: 10px;
      border: 0.1px solid var($primaryBorder);
      color: var($primaryColor);
      cursor: pointer;
      background-color: var($quaternaryBackground);
      z-index: 1;
    }
    .active {
      background-color: var($primaryBackground);
      color: var($secondaryColor);
    }
  }
}
.order-list {
  margin-top: 5vh;
  padding: 10px;
  width: 100vw;
  height: 55vh;
  display: flex;
  flex-direction: column;
}
