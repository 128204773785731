.search-category-list-container {
  margin-top: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100vw;
    .loader-container{
      display: flex;
      justify-content: center;
      align-items: center;
     height: 46vh;
    }
   .no-products-container {
    width: 100vw;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
  }