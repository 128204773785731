.search-Products-component {
    margin-top: 15px;
}

.search-products-container-main {
    
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.spinner-container-main {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-products-container {
    width: 100vw;
    height: 100px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-container::-webkit-scrollbar {
    display: none;
}
