.shimmer-wrapper {
  background: linear-gradient(
    to right,
    var(--softGrayGradient) 0%,
    var(--naturalToneGradient) 50%,
    var(--softGrayGradient) 100%
  );
  background-size: 200% auto;
  animation: shimmer 1.5s infinite linear;
  border-radius: 4px;
}
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
