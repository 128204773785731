.checkout-page-products {
  margin-bottom: 7px;
  cursor: pointer;
}
.product-tile-container {
  height: 5vh;
  display: flex;
}
.accordion-header {
  justify-content: space-between;
  width: 100%;
  height: 100%;
  text-decoration: none;
  border-radius: 15px;
  cursor: pointer;
  .accordion-length {
    font-size: 15px;
    font-weight: 500;
    margin-top: 3px;
    color: var($borderColor);
    margin-left: 6px;
  }
  text-transform: capitalize;
}
.product-tile {
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
  height: 100%;
  text-decoration: none;
  border-radius: 15px;
  margin-bottom: 10px;
  cursor: pointer;
}
.hide {
  visibility: hidden;
}
.products-accordion-header {
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  .accordion-inner-header {
    .accordion-count {
      color: var($borderColor);
    }
  }
}
.green {
  color: var($primaryTextColor);
}
.accordion-text-link {
  align-items: end;
  position: absolute;
  right: 1%;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  margin-top: 3px;
  color: var($primaryBackground);
}
