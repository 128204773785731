.product-tile-container {
  width: 40vw;
  height: 15vh;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image-container {
    .cart-item-image {
      width: 30vw;
      height: 15vh;
      border-radius: 10px;
    }
  }
}
.product-tile::-webkit-scrollbar {
  display: none;
}
