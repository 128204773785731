.toaster {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 16px;
  z-index: 10;
  max-width: 90%;
  position: fixed;
  top: 5%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  box-shadow: 0 0 10px var($boxShadow);
  text-align: left;
}
.success {
  background-color: var($tertiaryBackground);
}
.error {
  background-color: var($errorColor);
}
.info {
  background-color: var($infoColor);
}
.warning {
  background-color: var($warningColor);
}
.label {
  color: var($primaryColor);
  margin-left: 15;
  font-weight: 600;
  font-size: 20;
}
.close-icon {
  margin-left: 16px;
  font-size: 18px;
  font-weight: bold;
  color: var($primaryColor);
}
