.avatar-container .checkout-page-products .children-container {
  position: absolute;
  width: 88px;
  background: var($secondaryColor);
  right: 40%;
  top: 100%;
  border-radius: 8px;
  border: 1px solid var($whitesmokeBackground);
  z-index: 1;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
}
.avatar-container .products-accordion-header {
  border-bottom: none;
  display: none;
}
.dropdown-items {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 11px;

  cursor: pointer;
  border-bottom: 1px solid var($tertiaryBackground);
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;

  color: var($primaryColor);
}

.login-btn {
  padding: 0px 20px;
  height: 5vh;
  border: none;
  font-size: 17px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  background: var(--whitesmokeBackground);
}
.avatar-container {
  position: relative;
  padding-right: 15px;
}
