.category-accordion {
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  padding-top: 15px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.total-products {
  position: absolute;
  top: 0%;
  left: 25%;
  margin-top: 17px;
  // background-color: var($primaryBackground);
  color: var($primaryColor);
  padding: 2px 5px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
}
