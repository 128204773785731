// theme vairable are not getting import, will change it in future


.charge {
  padding: 20px;
  .grand-total {
    border-top: 1px solid rgb(199, 191, 191);
    padding: 1rem 0;
    font-weight: bold;
    font-size: 18px;
  }
  p {
    padding: 1rem 0;
    font-weight: bold;
  }
}
.order-summary-container {
    width: 100%;
    border-radius: 15px;
    background-color: var(--quaternaryBackground);
    color: var(--primaryColor);
    padding: 10px 0px;
    padding-bottom: 0px;
   .order-summary-bill{
    padding: 0px 10px;
    padding-bottom: 10px;
   } 
  .card-content {
    display: flex;
    align-items: center;
    text-align: left;
    color: #fff;
    width: 93.5vw;
    margin-left: -2px;
    justify-content: center;
    gap: 1rem;
    margin: 1rem 0rem;
    padding: 1rem;
  }
}
.order-summary{
  width: 93.5vw;
  margin-left: -2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.oreder-summary-inside{
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-summary-title{
  font-size: 15px;
  font-weight: 700;
  padding-bottom: 10px;
  padding-left: 14px;
}
.order-summary-items-total{
  padding: 0px 12px 8px;
  display: flex;
  font-weight: 500;
  color: var(--primaryColor);
}
.order-summary-img {
  height: 20px;
  width: 20px;
  object-fit: contain;
}
.order-summary-items-total-left{
  display: flex;
  gap: 8px;
  
}
.order-summary-items-total{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 500;
}
.order-summary-grand-total{
  display: flex;
  justify-content: space-between;
  padding: 0px 12px 8px;
  font-size: 14px;
  font-weight: 700;
}
.order-summary-discount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
  padding: 0 22px; 
  font-size: 14px;
  font-weight: 600;
  background: var(--discountBackgroundColor); 
  height: 40px; 
  border-bottom-left-radius: 15px; 
  border-bottom-right-radius: 15px; 
  box-sizing: border-box; 
  color: var(--discountColor);
  
}
