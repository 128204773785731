.category-small-card {
    width: 100%;
    padding: 2px 12px;
    text-decoration: none;
    .category-small-card-main {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 15px;

        .category-small-card {
            height: 50px;
            width: 50px;
            padding: 5px;

            .category-small-card-image {
                height: 100%;
                width: 100%;
                border-radius: 10px;
                border: 1px solid var($primaryTextColor);
            }
        }
        .category-small-cart-item-info{
            .category-small-cart-item-name{
                
                font-size: 17px;
                font-weight: 600;
                color: var($primaryColor);
                text-align: center;
            }
        }
    }
}