.search-input-container {
  width: 95%;
  height: 5.5vh;
  border-radius: 12px;
  display: flex;
  align-items: center;
  font-size: 13px;
  background-color: var($quaternaryBackground);
  padding: 0px 10px;
  justify-content: space-between;
  margin: 0 auto;
  .search-input-text-icon-container {
    display: flex;
    align-items: center;
  }
  .search-input-element {
    width: 50vw;
    height: 4.5vh;
    border-radius: 10px;
    border: none;
    outline: none;
    margin-left: 30px;
    font-size: 15px;
  }
}
