.otp-card-container {
  padding: 20px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px 0px 17px 5px rgba(0, 0, 0, 0.19);
  border-radius: 10px;

  p {
    font-size: 15px;
    margin-top: 25px;
  }

  p.user-number {
    font-weight: 700;
    margin-top: 10px;
  }

  .otp-input {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    input {
      padding: 10px;
      width: 50px;
      height: 50px;
      text-align: center;
      font-size: 20px;
      margin: 0 10px;
    }
  }

  button {
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    color: var($quaternaryBackground);
    font-size: 15px;
    border: none;
    margin-top: 20px;
    cursor: pointer;
    background-color: var($primaryBackground);
  }
}

@media (max-width: 450px) {
  .otp-card-container {
    width: 95%;
  }
}
