.button-comp-root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  border: 1px solid transparent;
  font-weight: 400;
  height: fit-content;
}

.button-comp-root:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.button-comp-filled {
  background-color: var($primaryButtonColor);
  color: var($secondaryColor);
  border-color: var($primaryButtonBorder);
}

.button-comp-filled:hover:not(.button-comp-disabled) {
  background-color: var($secondayButtonBorder);
  border-color: var($secondayButtonBorder);
}

.button-comp-outline {
  background-color: transparent;
  color: var($primaryButtonBorder);
  border-color: var($primaryButtonBorder);
}

.button-comp-outline:hover:not(.button-comp-disabled) {
  background-color: var($disableButtonColor);
  color: var($secondayButtonBorder);
  border-color: var($secondayButtonBorder);
}

.button-comp-disabled {
  pointer-events: none;
}
