.signIn-options-modal-container {
    padding: 20px;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: white;
    box-shadow: 0px 0px 17px 5px rgba(0, 0, 0, 0.19);
    border-radius: 10px;
  
    .signIn-options-modal-head {
      font-size: 25px;
      font-weight: 700;
    }
  
    .signIn-options-modal-text {
      font-weight: 400;
      font-size: 15px;
    }
  
    .signIn-options-modal-button-container {
      display: flex;
      gap: 15px;
  
      .signIn-options-modal-button {
        width: 100%;
        padding: 10px 20px;
        border-radius: 5px;
        color: var($quaternaryBackground);
        font-size: 15px;
        border: none;
        margin-top: 15px;
        cursor: pointer;
        background-color: var($primaryBackground);
      }
    }
  }
  
  @media (max-width: 450px) {
    .signIn-options-modal-container {
      width: 95%;
    }
  }