.request-deletion-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  border-radius: 8px;
  background-color: #fefefe;
  text-align: center;
}

.request-deletion-container h1 {
  color: #333;
}

.request-deletion-container p {
  color: #555;
  line-height: 1.6;
}

.deletion-form {
  margin-top: 20px;
}

.deletion-form label {
  display: block;
  margin-bottom: 10px;
  color: #333;
}

.deletion-form div {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.submit-button {
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.submit-button:hover {
  background-color: #0056b3;
}

.confirmation-message {
  margin-top: 20px;
  color: #28a745;
  font-weight: bold;
}

.policy-info {
  margin-top: 30px;
  font-size: 14px;
  color: #777;
}

.policy-info a {
  color: #007bff;
}

.policy-info a:hover {
  text-decoration: underline;
}
