.order-detail-container {
  background-color: var($whitesmokeBackground);
  padding: 16px;
  padding-bottom: 150px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 40px;
  position: relative;
  
  .delivery-time-container {
     padding: 10px;
  }
  
  .delivery-time-container > p {
    font-size: 16px;
    color: var($dateColor);
    font-weight: 400;
  }
  .delivery-time-container >h2{
    font-size: 26px;
    color: var($primaryBackground);
    font-weight: 700;
  }
 
  .order-detail-support-container {
    position: fixed;
    transform: translate(-50%,-50%);
    bottom: 8vh;
    right: -10px;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(130, 130, 130, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);

    .order-detail-support {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .order-detail-support-button {
        height: 35px;
        width: 35px;
        padding: 2px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .order-detail-support-circle {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .order-detail-support-circle-text {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;

        }

        .order-detail-support-circle-text span {
          position: absolute;
          font-size: 9px;
          font-weight: 700;
          transform-origin: center center;
         
        }

        .order-detail-support-circle-text span:nth-child(1) {
          transform: rotate(275.29deg) translateY(-25px) rotate(0deg);
        }
        
        .order-detail-support-circle-text span:nth-child(2) {
          transform: rotate(296.47deg) translateY(-25px) rotate(0deg);
        }
        
        .order-detail-support-circle-text span:nth-child(3) {
          transform: rotate(317.65deg) translateY(-25px) rotate(0deg);
        }
        
        .order-detail-support-circle-text span:nth-child(4) {
          transform: rotate(338.82deg) translateY(-25px) rotate(0deg);
        }
        
        .order-detail-support-circle-text span:nth-child(5) {
          transform: rotate(0deg) translateY(-25px) rotate(0deg);
        }
        
        .order-detail-support-circle-text span:nth-child(6) {
          transform: rotate(21.18deg) translateY(-25px) rotate(0deg);
        }
        
        .order-detail-support-circle-text span:nth-child(7) {
          transform: rotate(42.35deg) translateY(-25px) rotate(0deg);
        }
        
        .order-detail-support-circle-text span:nth-child(8) {
          transform: rotate(63.53deg) translateY(-25px) rotate(0deg);
        }
        
        .order-detail-support-circle-text span:nth-child(9) {
          transform: rotate(84.71deg) translateY(-25px) rotate(0deg);
        }
        
        .order-detail-support-circle-text span:nth-child(10) {
          transform: rotate(105.88deg) translateY(-25px) rotate(0deg);
        }
        
        .order-detail-support-circle-text span:nth-child(11) {
          transform: rotate(127.06deg) translateY(-25px) rotate(0deg);
        }
        
        .order-detail-support-circle-text span:nth-child(12) {
          transform: rotate(148.24deg) translateY(-25px) rotate(0deg);
        }
        
        .order-detail-support-circle-text span:nth-child(13) {
          transform: rotate(169.41deg) translateY(-25px) rotate(0deg);
        }
        
        .order-detail-support-circle-text span:nth-child(14) {
          transform: rotate(190.59deg) translateY(-25px) rotate(0deg);
        }
        
        .order-detail-support-circle-text span:nth-child(15) {
          transform: rotate(211.76deg) translateY(-25px) rotate(0deg);
        }
        
        .order-detail-support-circle-text span:nth-child(16) {
          transform: rotate(232.94deg) translateY(-25px) rotate(0deg);
        }
        
        .order-detail-support-circle-text span:nth-child(17) {
          transform: rotate(254.12deg) translateY(-25px) rotate(0deg);
        }      
      }
    }
  }

  .order-items-container {
    // width: 92vw;
    border-radius: 30px;
    background-color: var(--quaternaryBackground);
    color: var(--primaryColor);
    margin-top: 16px;

    .products-accordion-header {
      padding: 10px 20px;
    }

    .child-item-container {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      gap: 22px;

      .item-image-container {
        width: 125px;
        height: 100px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 12px;
        }
      }

      .item-info-container {
        line-height: 22px;
        width: 60%;

        .item-name {
          font-size: 16px;
          font-weight: bold;

          span {
            font-weight: 400;
            color: var($disableColor);
          }
        }

        .item-price {
          color: var($primaryBackground);
          font-weight: 500;
          margin-top: 8px;
        }

        .item-total {
          font-weight: 500;
          margin-top: 4px;
        }
      }
    }
  }

  .order-details-container {
    // width: 92vw;
    border-radius: 30px;
    background-color: var(--quaternaryBackground);
    color: var(--primaryColor);
    margin-top: 16px;

    .products-accordion-header {
      padding: 10px 20px;
    }

    .order-detail {
      padding: 10px 20px;
      line-height: 22px;

      .address-info-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        margin-bottom: 16px;

        .address-icon {
          width: 50px;

          img {
            width: 30px;
            height: 30px;
          }
        }

        .address-info {
          word-break: break-all;

          p {
            margin-bottom: 6px;
            font-weight: bold;
          }

          a {
            font-weight: 300;
            color: var($primaryColor);
            text-decoration: none;
          }
        }
      }

      .slot-payment-mode,
      .order-payment-info,
      .current-location {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;

        p:first-child {
          width: 140px;
          font-weight: bold;
        }

        p:last-child {
          font-weight: 400;
          text-transform: capitalize;
          word-break: break-all;
        }
        .order-payment-info-complete-payment-button{
          padding: 7px 28px;
          border-radius: 5px;
          background-color: var($primaryBackground);
          color: var($secondaryColor);
          border: none;
          outline: none;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 1.2px;
        }
      }

      .current-location {
        p:first-child {
          width: max-content;
          font-weight: bold;
        }

        p:last-child {
          text-transform: none;
        }
      }

      .total-amount {
        margin-top: 16px;
        font-weight: bold;

        span {
          font-weight: 500;
        }
      }
    }
  }
}

.order-pending-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 15px 20px;
  width: 92vw;
  border-radius: 30px;

  .complete-payment-button {
    padding: 8px 12px;
    color: #27ae60;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
  }
}


.delivery-info {
  background-color: var(--primaryBackground);
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 16px;
  color: var(--primaryColor);
  font-size: 16px;
}

.delivery-info-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delivery-info p {
  margin: 5px 0;
  font-weight: bold;
}

.delivery-info .distance,
.delivery-info .duration {
  flex-basis: 48%;
  text-align: center;
}

.delivery-info .distance {
  color: var(--secondaryColor);
}

.delivery-info .duration {
  color: var(--tertiaryColor);
}

.order-pending-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondaryColor);;
  padding: 15px 20px;
  width: 92vw;
  border-radius: 30px;
  margin-top: 16px;
}

.complete-payment-button {
  padding: 8px 12px;
  color: #27ae60;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
}
