.address-accordion-container {
  padding: 20px 20px;
  padding-top: 0px;
  .previous-address-input {
    display: flex;
    flex-direction: column;
    .previous-label {
      margin-top: 5px;
    }
  }
  .address-form {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .address-heading {
      margin-bottom: 20px;
    }
    .address-field-container {
      display: flex;
      flex-direction: column;
      padding-top: 5px;
      margin-bottom: 10px;
      .address-input {
        outline: none;
        border: none;
        border-bottom: 1px solid rgb(199, 191, 191);
        padding-left: 10px;
        font-size: 15px;
      }
      .address-input::placeholder {
        color: var($placeholderColor);
      }
      .address-heading {
        height: 1vh;
        .required-field {
          color: red;
        }
      }
    }
    .error-message{
      color:red;
      font-size: 12px;
      padding-top: 4px;
    }
  }
}
.or-container {
  text-align: center;
  margin-bottom: 10px;
}

.location-container {
  .location-button {
    width: 100%;
    background-color: var($quaternaryBackground);
    border: 1px solid var($borderColor);
    border-radius: 5px;
    color: var($primaryColor);
    font-size: 14px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .location-icon {
    margin-right: 10px;
    width: 14px;
    height: 14px;
  }
  .disabled-location-btn {
    background-color: var($tertiaryBackground);
    color: var($secondaryColor);
  }
  .location-text {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
}

.address-accordion-btn-container {
  display: flex;
  gap: 8px;
}