.cart {
  width: 100vw;
  height: 100%;
  overflow-y: hidden;
  color: var($secondaryColor);
  .cart-page-header-container {
    height: 10vh;
    width: 90vw;
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .back-arrow-image {
      cursor: pointer;
    }
    .cart-heading {
      font-size: 30px;
      font-weight: 800;
    }
  }

  .main-content {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 74vh;
    width: 100vw;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background-color: var($whitesmokeBackground);
    padding-top: 10px;
    padding-left: 9px;
    .cart-items {
      width: 93.5vw;
      border-radius: 15px;
      margin-left: -2px;
      background-color: var($quaternaryBackground);
      color: var($primaryColor);
    }
  }
  .main-content::-webkit-scrollbar {
    display: none;
  }
}

.cart-item-info {
  width: 50vw;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .cart-item-mrp {
    font-size: 10px;
    color: var($errorTextColor);
    margin-bottom: 10px;
  }
  .cart-item-sp {
    font-size: 17px;
    font-weight: 800;
    color: var($primaryTextColor);
  }
}