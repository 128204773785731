.time-slot-accordion-container {
  padding: 20px 20px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .time-slot-container {
    height: 5vh;
    width: 40vw;
    font-size: 15px;
    font-weight: 600;
    border: none;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-top: 10px;
    background-color: var($quaternaryBackground);
    color: var($primaryColor);
    outline: none;
    cursor: pointer;
    .timeslot-option {
      font-weight: 600;
      background-color: var($quaternaryBackground);
      color: var($primaryColor);
      border: none;
    }
  }
}
