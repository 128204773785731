.product-container {
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 10px;
  height: fit-content;
  width: 30vw;
  
  margin: auto;
  .product-image-btn-container {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    .product-image-container {
      width: 100%;
      height: 10vh;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      .product-image {
        width: 30vw;
        height: 12vh;
        border-radius: 10px;
      }
    }
  }

  .product-info {
    margin-top: 2px;
    height: 12vh;
    display: flex;
    flex-direction: column;
    height: inherit;
    width: 100%;
    .product-unit-info{
      font-size: 10px;
      color: var($unitQuanityColor);
      width: fit-content;
      padding: 2px 8px;
      border-radius: 5px;
      margin-top: 5px;
      background-color: var($cartImageBackground);
    }

    .product-name {
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 3px;
      margin-top: 10px;
    }
    .product-mrp {
      font-size: 11px;
      color: var($errorTextColor);
      .mrp-amount{
        padding-left: 4px;
      }
    }
    .product-sp {
      font-size: 17px;
      padding-bottom: 5px;
      font-weight: 800;
      color: var($primaryTextColor);
    }
  }
  .product-info::-webkit-scrollbar{
    display: none;
  }

  .add-product-button {
    width: 20vw;
    height: 4vh;
    background-color: var($primaryBackground);
    border: none;
    color: var($secondaryColor);
    font-weight: 600;
    border-radius: 5px;
    font-size: 20px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .salb-table {
    font-size: 16px;
    border-collapse: collapse;
    margin-top: 5px;
    width: 100px;
    td,
    th {
      padding: 5px 6px;
      text-align: left;
      font-size: 12px;
    }

    thead tr {
      background-color: var($primaryBackground);
      color: var($secondaryColor);
    }

    tbody tr:nth-child(odd) {
      background-color: var($secondryBackground);
    }

    tbody tr:nth-child(even) {
      background-color: var($whitesmokeBackground);
    }
  }
}

.slap-accordion {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: -27px;
  margin-left: 98px;
}

.mrp-sp-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
}

.multi-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  max-width: 200px;
}
.mrp-price-product-mrp-amount{
  padding-left: 4px;
}
.accordion-toggle{
  height: 27px;
  width: 20px;
}