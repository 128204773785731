.products-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 80vh;
  border-radius: 30px;
  background-color: var($quaternaryBackground);
  border-top: 1px solid var($secondaryColor);
  padding-bottom: 10vh;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 12px;

}
.products-container-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-container-main {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-products-container {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .no-products-text {
    font-size: 1.5rem;
    font-weight: 600;
    color: var($primaryColor);
  }
}
.no-result-search-message{
  font-weight: 600;
  width: 100vw;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.product-container::-webkit-scrollbar {
  display: none;
}