@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

@import "./theme";

body[data-theme="light"] {
  @include spread-map($theme-map-light);
}

body[data-theme="dark"] {
  @include spread-map($theme-map-dark);
}

@import "../components/index";
@import "../pages/index";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $primaryFont;
}

.container {
  width: 100%;
  background-color: var($primaryBackground);
  height: 100dvh;
  overflow: hidden;
  display: grid;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    display: none;
  }
}

.home-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var($primaryBackground);
  height: 80vh;
  width: 100vw;
  
  .main-content {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background-color: #e9f7ef;
    padding: 8px;
    padding-bottom: 45px;
  }
  .main-content::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
.full-page-home-container{
  height: 100vh;
  padding: 0px;
  .main-content{
    padding: 0px;
    border-radius: 0;
  }
}