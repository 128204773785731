.card-container {
  padding: 0px 0px;

  .card-content {
    padding: 0px;

    // To hide scroll bar
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.card-container:nth-child(odd) {
}
