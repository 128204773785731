.footer-container {
  height: 10vh;
  width: 100%;
  background-color: var($primaryBackground);
  display: flex;
  padding: 20px 10px;
  justify-content: space-around;
  align-items: center;
  position: sticky;
  bottom: 0;
  border-top: 1px solid var($secondaryColor);
  .navbar-options{
    width: 30px;
  }
  .iconContainer {
    width: 30px;
    height: 30px;
    padding-bottom: 10px;
    text-decoration: none;
    margin-bottom: 10px;
    .footerIcon {
      width: 30px;
      height: 30px;
    }
    .userIcon {
      filter: invert(1);
    }
    .text {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      font-weight: 600;
      color: var($secondaryColor);
    }
  }
}
