.discount-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var($primaryBoxShadowColor);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.discount-modal-container {
  background: var($quaternaryBackground);
  border-radius: 15px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 10px 30px var($primaryBoxShadowColor);
  transform: scale(0.8);
  animation: discount-modal-pop 0.5s ease-out forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 250px;
}

@keyframes discount-modal-pop {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  text-align: end;
  width: 100%;
}

.confetti-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.confetti {
  position: absolute;
  top: -10px;
  border-radius: 50%;
  animation: confetti-fall linear forwards;
}

@keyframes confetti-fall {
  0% {
    transform: translateY(0) rotate(0deg);
    top: -100px;
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
    top: 0px;
  }
}

.discount-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #ff5722;
  animation: blink 1s infinite alternate;
}

.discount-amount {
  font-size: 1.5rem;
  margin-top: 10px;
  color: var($quinaryBackgroud);
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.discount-modal-description {
  margin-top: 10px;
  font-size: 1rem;
  color: #555;
}
