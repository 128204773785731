.no-product-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  text-align: center;
  color: var($errorTextColor);
  font-size: 18px;
  line-height: 1.5;
  .no-product-message-icon {
    margin-bottom: 20px;
    width: 90px;
    height: 90px;
  }
  .no-product-message-text {
    margin-bottom: 20px;
    font-size: 20px;
  }
  .no-product-message-button {
    width: 100%;
    max-width: 200px;
    text-decoration: none;
  }
}
