.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinner-img {
      width: 50%;
      height: 50%;
      object-fit: contain;
    }
    .loader {
      width: 100%;
      position: absolute;
      top: 0;
      margin: 0 auto;
      padding: 1px;
    }
    .loader:before {
      content: "";
      border-radius: 10px;
      position: absolute;
      top: -4px;
      right: -4px;
      bottom: -4px;
      left: -4px;
    }
    .loader .loaderBar {
      height: 7px;
      background: var($primaryTextColor);
      position: absolute;
      border-radius: 10px;
      top: 0;
      right: 100%;
      bottom: 0;
      left: 0;
      width: 0;
      animation: borealisBar 2s linear infinite;
    }

    @keyframes borealisBar {
      0% {
        left: 0%;
        right: 100%;
        width: 0%;
      }
      10% {
        left: 0%;
        right: 75%;
        width: 25%;
      }
      90% {
        right: 0%;
        left: 75%;
        width: 60%;
      }
      100% {
        left: 100%;
        right: 0%;
        width: 100%;
      }
    }
  }
}
