.category-card-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 120px;
  text-decoration: none;
  border-radius: 15px;
  overflow-y: hidden;
  padding: 10px 0px;
  overflow-y: scroll;
  overflow-x: hidden;

  margin-top: 10px;
}
.category-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 15px;
  background-color: var($primaryBackgroundCard);
  overflow-y: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}
.cart-item-info {
  display: flex;
  flex-direction: column;
  height: 30%;
  margin-top: 10px;
  border-radius: 15px;
  .cart-item-name {
    font-size: 12px;
    font-weight: 600;
    color: var($primaryColor);
    text-align: center;
  }
}
.category-page {
  text-decoration: none;
}
