.searchContainer {
  margin: auto;
}

.header {
  margin-bottom: 20px;
}
.searchBar {
  padding: 20px;
  width: 100%;
  position: fixed; 
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  
}

.searchBarContainer {
  display: flex;
  flex-direction: column; 
  align-items: stretch;
  gap: 10px;
   position: relative; 
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 54px;
  width: 100%; 
}

.searchIcon {
  position: absolute;
  left: 10px; 
  width: 20px;  
  height: 20px;
  pointer-events: none; 
}

.searchInput {
  padding: 10px 40px 10px 35px !important;
  font-size: 15px;
  outline: none;
  border: 1px solid var($searchInpurtBorderColor);
  border-radius: 4px;
  color: var($searchInputColor);
  font-weight: 500;
  width: 100%; 
}

.addressSuggestionDrawer{
  position: absolute;
  top: 89%; 
  left: 0;
  right: 0;
  background-color: var($secondaryColor);
  box-shadow: 0 4px 6px var($boxShadow);
  border-radius: 4px;
  overflow-y: auto; 
  z-index: 1000;
}


.suggestionsDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var($secondaryColor);
  border-radius: 4px;
  max-height: 200px; 
  overflow-y: auto;
  z-index: 1000; 
  padding: 0;
  margin: 0;
  list-style: none;
}

.suggestionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px; 
  padding:  10px 5px;
  cursor: pointer;
  color: var($dateColor);
  font-size: 14px;
  margin-bottom: 5px;
  
}
.suggestionItem-address {
  flex: 1;
  margin: 0;
  text-align: left; 
  word-break: break-word; 
}
.suggestionItem:hover {
  background-color:var($whitesmokeBackground);
}

.suggestionsContainer {
  margin-top: 20px;
  width: 100%; 
}

.suggestionsList {
  list-style-type: none;
  padding: 10px 5px;
  margin: 0;
  border-radius: 10px;
  overflow: hidden;
}

.clearButton {
  position: absolute;
  right: 10px; 
  background: transparent;
  border: none;
  font-size: 12px;
  cursor: pointer;
  color: var($clearButtonColor);
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.2s ease;
}

.clearButton:hover {
  background-color: var($suggestionItemBorderColor);
}

.noSuggestions {
  color:var($dateColor);
  font-size: 14px;
  padding: 10px;
}

.addressSuggestionDrawerLoader{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; 
  margin: 10px;
  z-index: 10;
}

