.place-order-button {
  height: 17vh;

  .place-order-address {
    height: auto;
    background-color: var(--quaternaryBackground);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 12px;
    box-shadow: var($primaryBoxShadowColor) 0px 0px 10px 0px;
    
  }

  border: none;
  border-radius: 5px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.isOrderPlaced {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 8vh;
  z-index: 99999;
  display: flex;
  width: 100vw;
  height: 77vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var($quaternaryBackground);
  border: 1px solid var($primaryBorder);
}
.place-order-address-img {
  height: 20px;
  width: 20px;
}

.place-order-button[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}
.place-order-address-section {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  padding-bottom: 10px;
}
.place-order-address-left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.place-order-address-content-header {
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
}
.place-order-address-content-bottom {
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  margin-top: 2px;
  color: var($selectAddressColor);
}
.place-order-address-right-button {
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  color: var($primaryBackground);
}
.place-order-submit-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
.place-order-submit {
  display: flex;
  width: 93.5vw;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 24px;
  background-color: var($primaryBackground);
  -webkit-box-align: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  margin-left: -2px;
  color: var($secondaryColor);
  -webkit-box-pack: center;
  justify-content: center;
}
.place-order-shimmer-content {
 
  display: flex;
  width: 93.5vw;
  gap: 15px;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 10px;
}

.place-order-shimmer-image {
  width: 50px;
  height: 48px;
}

.place-order-shimmer-address {
  width: 100%;
  height: 48px;
}

