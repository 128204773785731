.nav-container {
  padding: 0.5rem 0.5rem;
  .header-container {
    justify-content: center;
  }
  position: relative;
  height: fit-content;
  
}
.nav-container-main{
  display: flex;
  align-items: center;
}
.container-on-checkout {
  height: 82px;
  position: relative;
  height: 11vh;
  align-content: center;
}
.nav-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  .heading-position {
    color: var($secondaryColor);
    font-size: 20px;
    font-weight: 700;
    margin-left: -1rem;
    text-transform: capitalize;
  }
  button {
    background: transparent;
    border: none;
    width: 10px;
    height: 10px;
    margin-left: 1rem;
    .arrow-left {
      width: 100%;
      height: 100%;
      display: block;
      border-top: 3px solid var($secondaryColor);
      border-left: 3px solid var($secondaryColor);
      transform: rotate(-45deg);
    }
  }
}
.nav-header-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scarch-display-container {
  position: absolute;
  z-index: 3;
  top: 100%;
  left: 0;
  background: var( $secondryBackground);
  width: 100%;
  height: 0; 
  overflow: hidden; 
  transition: height 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.scarch-display-container.open {
  height: 50svh; 
  overflow-y: auto; 
}

.scarch-display-container::-webkit-scrollbar{
  display: none;
}


/* Location Info Container */
.location-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border-radius: 8px;
}

.delivery-time-container > p {
  color: var($secondaryColor);
  font-weight: 600;
  font-size: 13px;
}

.nav-container-address-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.location-info > .delivery-time-container > .delivery-time {
  color: var($secondaryColor);
  font-weight: 800;
  font-size: 26px;

}
.location-info > .current-address-container {
  display: flex;
  align-items: center;
}
.location-info > .current-address-container >.current-address-text {
  font-size: 15px;
  color: var($secondaryColor);
  font-weight: 400;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
  max-width: 50vw;
  text-overflow: ellipsis;
}



/* Resync Location Icon */
.location-info .resync-icon {
  cursor: pointer;
  color: var($secondaryColor);
  font-size: 18px;
  margin-left: 8px;
  transition: all 0.3s ease;
}
.location-info .dropdown-icon {
  cursor: pointer;
  font-size: 12px;
  color: var($secondaryColor);
  margin-left: 8px;
}
.location-info .resync-icon-loader {
  display: inline-block;
  animation: rotate 2s linear infinite;
}

/* Keyframe for rotation */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .location-info {
    padding: 0px 5px;
  }

  .location-button {
    padding: 10px 20px;
  }
}

.delivery-time-container-on-checkout {
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: var($secondaryColor);
  font-weight: 500;
  clear: both;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
}