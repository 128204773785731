.footer-cart-container-circle {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: var($quaternaryBackground);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 60%;
  left: 44%;
  border: 5px solid var($primaryBackground);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(114, 114, 114, 0.19);
  .product-counter-container {
    width: 30px;
    height: 30px;
    border-radius: 25%;
    position: absolute;
    top: -10%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var($quinaryBackgroud);
    color: var($secondaryColor);
    display: flex;
    justify-content: center;
    align-items: center;

    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 64%,
      78% 81%,
      51% 100%,
      23% 81%,
      0 64%
    );
  }
  .cart-icon-img-animation {
    animation: rotate 0.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
