.previous-address-input {
  padding: 10px 0px;
  height: 200px;
  margin: 0px 15px;
  border: 5px solid var($whitesmokeBackground);
  background-color: var($whitesmokeBackground);
  border-radius: 5px;
  overflow-y: scroll;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .previous-address-container {
    margin-bottom: 10px;
    width: 48%;
    .previous-address-data {
      background-color: transparent;
      cursor: pointer;
      padding: 5px;
      border-radius: 5px;
      background-color: var($secondaryColor);
      font-size: 14px;
      box-shadow: 0 0 5px var($boxShadow);
      line-height: 1.5;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .location-image {
        height: 25px;
        margin-right: 2px;
      }
      .location-text {
        color: var($primaryTextColor);
        text-decoration: none;
      }
    }
  }
}
