.cart-checkout {
  position: sticky;
  bottom: 0%;
  width: 100vw;
  height: 11vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  color: var($secondaryColor);
  .cart-checkout-total {
    font-size: 23px;
    
  }
}
