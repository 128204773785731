.order-card {
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1px solid var($primaryBorder);
  margin-bottom: 10px;
  padding: 10px 10px;
  box-sizing: border-box;
  color: var($primaryColor);
  .order-card-header-image {
    width: 10vh;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .order-card-body {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .order-card-body-left {
      display: flex;
      height: 100%;
      padding: 5px;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      .order-card-body-left-text {
        span {
          color: var($primaryBackground);
          font-weight: 700;
        }
      }
      .order-status {
        display: flex;
        width: 18vh;
        justify-content: center;
        align-items: center;
        height: 4vh;
        background-color: var($primaryButtonColor);
        border-radius: 8px;
        font-size: 10px;
        &.order-payment-incomplete{
          color:  red;
          font-size: 12px;
          background-color: rgba(205, 131, 131, 0.457);
        }
      }
    }
    .order-card-body-right {
      display: flex;
      width: 20%;
      font-size: 10px;
      font-weight: 500;
      height: 100%;
      align-items: flex-end;
      color: var($errorTextColor);
    }
  }
}
