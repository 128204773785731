.track-order-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  .track-order-heading-container {
    height: 10vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    .track-order-heading {
      font-size: 30px;
      color: var($secondaryColor);
    }
  }
  .main-content {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 70vh;
    width: 100vw;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background-color: var($whitesmokeBackground);
    padding: 15px;
  }
}
