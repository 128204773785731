
.order-success {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var($quaternaryBackground);
    border: 1px solid var($primaryBorder);
  }
  
  .order-success-icon {
    width: 100px;
    color: var($primaryTextColor);
    margin-bottom: 15px;
  }
  .order-placed-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .order-success-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .order-success-message h2 {
    font-size: 29px;
    margin-bottom: 20px;
  }
  
  .order-success-message p {
    width: 70%;
    font-size: 19px;
    line-height: 27px;
  }
  