.checkout-page-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 40px 40px 0 0;
  overflow-y: scroll;
  justify-content: space-between;
  .checkout-page-heading-container {
    height: 10vh;
    width: 90vw;
    padding: 0px 10px;
    font-weight: 800;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .checkout-page-heading {
      font-size: 30px;
      color: var($secondaryColor);
    }
  }
  .main-content {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 90vh;
    width: 100vw;
    background-color: var($whitesmokeBackground);
    .payable-amount-container {
      height: 10vh;
      width: 90vw;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 800;
    }
    .checkout-page-products {
      width: 93.5vw;
      border-radius: 15px;
      margin-left: -2px;
      background-color: var($quaternaryBackground);
      color: var($primaryColor);
      .products-accordion-header {
        display: flex;
        justify-content: space-between;
        padding: 20px 20px;
        .accordion-header {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }
  .main-content::-webkit-scrollbar {
    display: none;
  }
}
.cancellation-policy-container{
  width: 93.5vw;
  border-radius: 15px;
  background-color: var(--quaternaryBackground);
  color: var(--primaryColor);
  padding: 10px;
  margin-top: 7px;
  margin-bottom: 7px;
  margin-left: -2px;
}
.cancellation-policy-container-heading{
  font-size: 15px;
  line-height: 20px;
  padding-left: 15px;
  font-weight: 700;
  padding-bottom: 4px;
    
}
.cancellation-policy-container-text{
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 3px 15px;
  padding-bottom: 12px;
  color: var($selectAddressColor);

}
.payment-method-container{
  width: 93.5vw;
  border-radius: 15px;
  background-color: var(--quaternaryBackground);
  color: var(--primaryColor);
  padding: 10px;
  margin-top: 7px;
  margin-bottom: 7px;
}
.payment-method{
  display: flex;
  justify-content: space-between;
}
.payment-method-heading{
  font-size: 15px;
  line-height: 20px;
  padding-left: 15px;
  font-weight: 700;
}

.payment-contactNumber-container {
  width: 93.5vw;
  border-radius: 15px;
  background-color: var(--quaternaryBackground);
  color: var(--primaryColor);
  padding: 10px;
  margin-top: 7px;
  margin-bottom: 7px;

  .payment-contactNumber {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .payment-contactNumber-heading {
      font-size: 15px;
      line-height: 20px;
      padding-left: 15px;
      font-weight: 700;
    }
    .payment-contactNumber-input-container {

      height: 3.5vh;  
      width: 40vw;    
      border-radius: 5px;
      border: .2px solid var($borderColor);
      margin-right: 10px;
      padding: 0px 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      .payment-contactNumber-text{
        color: #000;
        font-size: 15px;
        letter-spacing: 2px;
        font-weight: 500;
      }
      
      .payment-contactNumber-input {    
        width: 100%;   
        height: 100%;
        border: none;
        outline: none;
        color: #000;
        font-size: 15px;
        letter-spacing: 2px;
        font-weight: 500;
      }
    }
  }
}