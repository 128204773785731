.popular-accordion {
    background-color: white;
    padding:  20px 0px;
    padding-bottom: 0;

    .popular-accordion-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .popular-accordion-header-text {
            font-weight: 700;

            .popular-accordion-length {


                font-size: 15px;
                font-weight: 500;
                margin-top: 3px;
                color: var(--borderColor);
                margin-left: 6px;
            }
        }


        .popular-accordion-text-link {

            text-decoration: none;
            font-size: 15px;
            font-weight: 500;
            margin-top: 3px;
            color: var($primaryBackground);
        }
    }

    .popular-accordion-products-container {
        overflow-x: scroll;
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

    }

    .popular-accordion-products-container::-webkit-scrollbar {
        display: none;
    }


}