.required-field {
  color: red;
}
.contact-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  height: 5vh;
  margin-left: 10px;
  outline: none;
  text-align: center;
  .input-container {
    display: flex;
    align-items: center;
    .country-code {
      margin-right: 10px;
    }
    .flag-image {
      height: 20px;
      width: 20px;
      margin-right: 5px;
    }
    .contact-number-input {
      outline: none;
      height: 5vh;
      padding-left: 10px;
      font-size: 15px;
      border: none;
      border-bottom: 1px solid #cacaca;
      letter-spacing: 4px;
      width: 50%;
      @media screen and (max-width: 355px) {
        width: 60%;
      }
      @media screen and (max-width: 310px) {
        width: 100%;
      }
    }
  }

  .error-message {
    color: red;
    font-size: 15px;
    margin-left: 10px;
  }
}
