.checkout-component-button-container {
  cursor: pointer;
  .checkout-component-button {
    width: 38vw;
    height: 5vh;
    background-color: var(--quaternaryBackground);
    border: none;
    color: var($primaryTextColor);
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    padding: 0 3px;
  }
}
