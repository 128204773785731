.category-page-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-around;
  width: 100%;
  padding-bottom: 20px;
  // background-color: var($primaryBackground);
  background-color: var($secondaryColor);
  .category-page-container__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1rem 0;
  }
}
.spinner-container-main {
  width: 100vh;
  height: 75vh;
  display: flex;
  background-color: #e9f7ef;
  align-items: center;
  justify-content: center;
}
