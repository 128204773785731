@import "../../styles/theme";

.terms-and-conditions-component {
  color: var($primaryColor);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .terms-and-conditions-head {
    position: sticky;
    top: 0%;
    left: 0%;
    height: 55px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    .terms-and-conditions-head-h2 {
      font-size: 32px;
      color: #000;
    }
    .back {
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
    }
  }
  .terms-and-conditions-body {
    padding: 20px 50px;
    width: 100%;
  }
}
