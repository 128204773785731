@import "../../styles/theme";
.profile-container {
  // width: 100vw;
  // height: 74vh;
  // overflow-x: scroll;
  background-color: var($whitesmokeBackground);
  border-radius: 24px 24px 0px 0px;
  padding: 16px;
  .profile-title {
    border: 1px solid var($borderColor);
    padding: 16px;
    border-radius: 9px;
    background: var($secondaryColor);
    h3 {
      font-size: 18px;
      margin: 16px 0rem;
    }
    p {
      font-weight: 400;
    }
    .profile-image-container {
      position: relative;
      width: 100px;
      height: 100px;
      background-color: var($tertiaryBackground);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 60px;
      color: var(--quaternaryBackground);
      display:flex;
      align-items: center;
      justify-content: center;
      .profile-image-cover{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;       
        .profile-image{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .image-file-input{
        display: none;
      }
      .add-upload-image-button{
        position: absolute;
        top: 65%;
        left: 65%;
        .button-cover{
          padding: 6px;
          border-radius: 50%;
          background-color: var($quaternaryBackground);       
          display: flex;
          align-items: center;
          justify-content: center;
          height: 35px;
          width: 35px;
          .button-cover-icon{
            height: 100%;
            width: 100%;
          }
        }        
        .delete-upload-button-cover{
          display: flex;
          gap: 3px;
          background-color: var($quaternaryBackground);       
          border-radius: 35px;
        }
      }
    }
  }
  .profile-info {
    div {
      p {
        color: var($errorTextColor);
      }
      .profile-name {
        text-transform: capitalize;
      }
      p:first-child {
        font-weight: 500;
        font-size: 18px;
        color: var($primaryColor);
        margin-bottom: 10px;
      }
      padding: 10px;
    }
    .profile-name-container {
      .profile-name {
        font-size: 22px;
        font-weight: 500;
        color: var($primaryColor);
        padding: 10px 10px;
        margin-bottom: 20px;
      }
      .edit-profile {
        font-size: 20px;
        cursor: pointer;
        background-color: transparent;
        padding: 10px 10px;
        border: 1px solid var($borderColor);
      }
      .btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 40%;
        height: 8vw;
        background-color: var($primaryBackground);
        border-radius: 8px;
        border: 1px solid var($borderColor);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .profile-address {
    margin-top: 2rem;
    .address-container {
      display: flex;
      align-items: center;
      padding: 10px;
      gap: 16px;
      .location-svg {
        width: 30px;
        height: 30px;
        margin-right: 20px;
      }
      .address-info {
        width: 100%;
        display: flex;
        flex-direction: column;

        p {
          margin-top: 4px;
          color: var($errorTextColor);
          margin: 7px 0px;
        }
        p:first-child {
          color: var($primaryColor);
          font-weight: 550;
          font-size: 16px;
        }
        p:last-child {
          color: var($primaryColor);
        }
      }
    }
  }
  .delete-account-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    height: 8vw;
    background-color: var($primaryBackground);
    border-radius: 8px;
    border: 1px solid var($borderColor);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
  }
}
