.payment-accordion-container {
  padding-right: 10px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  .payment-select {
    border-radius: 5px;
    padding: 0px 7px;
    width: 20vw;
    height: 3vh;
    background-color: var($primaryBackground);
    color: var($secondaryColor);
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 12px;
    .payment-option {
      background-color: var($addressDrawerBackground);
      color: var($primaryColor);

      border: none;
      outline: none;
      font-weight: 600;
    }
  }
  .desclaimer {
    margin-top: 10px;
    font-size: 12px;
    color: var($primaryColor);
    font-weight: 600;
    .desclaimer-text {
      color: var($primaryColor);
      font-weight: 600;
    }
  }
}
