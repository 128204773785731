@import "../../styles/theme";
.product-page {
  font-family: Arial, sans-serif;
  background-color: var($primaryBackgroundCard);
  height: calc(100vh - 75px);
  overflow: auto;

  .product-header {
    position: relative;
    text-align: center;

    .back-button {
      position: fixed;
      top: 16px;
      left: 16px;
      background-color: var($primaryBackground);
      color: var($quaternaryBackground);
      border: none;
      font-size: 20px;
      cursor: pointer;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      .back-button-arrow {
         font-size: 20px;
         font-weight: 600;
      }
    }

    .product-image {
      width: 100%;
      max-height: 250px;
      object-fit: cover;
    }
  }

  .product-info-wrapper {
    text-align: center;
    margin-top: 16px;
    padding: 16px;
    margin: 16px;
    border-radius: 8px;
    background-color: var($quaternaryBackground);

    .product-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 4px;
    }

    .product-subtitle {
      font-size: 16px;
      color: #888;
      margin-bottom: 16px;
    }

    .product-details {
      display: flex;
      justify-content: center;
      font-size: 16px;
      gap: 10px;
      color: #555;
      margin-bottom: 16px;
      align-items: center;
      font-weight: bold;
      .selling-price{
        font-size: 18px;
        color: var($primaryBackground);
      }
      .mrp-price {
        color: inherit;
        text-decoration: line-through;
        text-decoration-color: var($errorColor);
        text-decoration-thickness: 1.5px;
      }
    }

    .quantity-controls {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      > div {
        background-color: var($primaryBackground);
      }
      .quantity-btn {
        width: 32px;
        height: 32px;
        background-color: var($primaryBackground);
        color: var($quaternaryBackground);
        border: none;
        border-radius: 10px;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:disabled{
          opacity: 0.7;
        }
      }

      .quantity {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  
  .slab-info {
    text-align: center;
    margin-top: 16px;
    padding: 16px;
    margin: 16px;
    border-radius: 8px;
    background-color: var($quaternaryBackground);

    .slab-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 4px;
    }
    .slab-table {
      font-size: 16px;
      border-collapse: collapse;
      margin-top: 15px;
      width: 100%;
      td,
      th {
        padding: 5px 15px;
        text-align: left;
      }
  
      thead tr {
        background-color: var($primaryBackground);
        color: var($secondaryColor);
      }
  
      tbody tr:nth-child(odd) {
        background-color: var($secondryBackground);
      }
  
      tbody tr:nth-child(even) {
        background-color: var($whitesmokeBackground);
      }
    }
  }  

  .similar-products {
    padding: 16px;
    margin: 16px;
    background-color: var($quaternaryBackground);
    border-radius: 8px;

    h2 {
      font-size: 18px;
      margin-bottom: 16px;
    }

    .product-carousel {
      display: flex;
      gap: 16px;
      overflow-x: auto;

      .product-item {
        flex: 0 0 auto;
        width: 80px;

        .carousel-image {
          width: 100%;
          border-radius: 8px;
        }
      }
    }
  }

  .add-to-cart {
    padding: 16px;
    background-color: var($quaternaryBackground);
    border-radius: 8px;
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: 0px 6px 7px 4px gray;
    .add-to-cart-content{
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      .cart-price {
        display: flex;
        flex-direction: column;
  
        .price {
          font-size: 20px;
          font-weight: bold;
          color: var($primaryBackground);
        }
      }
  
      .cart-button {
        background-color: var($primaryBackground);
        color: var($quaternaryBackground);
        border: none;
        padding: 12px 24px;
        border-radius: 8px;
        font-size: 16px;
        &:disabled {
          opacity: 0.7;
        }
      }
    }
  }
}

.LoadingSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var($primaryBackgroundCard);
}
