.login-card-container {
  padding: 20px;
  width: 400px;
  background-color: white;
  box-shadow: 0px 0px 17px 5px rgba(0, 0, 0, 0.19);
  border-radius: 10px;

  .icon-input-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 100%;

    img {
      width: 35px;
    }

    p {
      font-size: 20px;
      margin: 0 10px;
      color: var($primaryColor);
    }

    input {
      padding: 5px 5px;
      font-size: 20px;
      border: none;
      outline: none;
      border-bottom: 1px solid var($borderColor);
      width: 100%;
    }
  }

  .terms-checkbox {
    margin-top: 20px;
    display: flex;
    align-items: start;
    gap: 10px;

    .terms-checkbox-input {
      height: 25px;
      width: 25px;
      border: 1px solid var($borderColor);
    }

    .terms-checkbox-label {
      color: var($errorTextColor);

      .terms-checkbox-label-a {
        font-size: 15px;
        color: var($primaryTextColor);
      }
    }
  }

  p {
    font-size: 14px;
    color: var($errorTextColor);
    margin-top: 20px;
  }

  button {
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    color: var($secondaryColor);
    font-size: 15px;
    border: none;
    margin-top: 20px;
    cursor: pointer;
  }

  .approve {
    background-color: var($primaryBackground);
  }

  .disapprove {
    background-color: var($disableColor);
  }
}

.otp-btn {
  position: relative;
  overflow: hidden;
}

@media (max-width: 450px) {
  .login-card-container {
    width: 95%;
  }
}
