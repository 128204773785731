.drawer-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var($backdropShadow);
    z-index: 999;
  }
  
  .drawer {
    position: fixed;
    background: var($secondaryColor);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    box-shadow: 0 2px 8px var($primaryBoxShadowColor);
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 24px 16px 16px 16px;
  }
  
  .drawer-left {
    top: 0;
    left: 0;
    height: 100%;
    width: fit-content;
    transform: translateX(-100%);
  }
  
  .drawer-right {
    top: 0;
    right: 0;
    height: 100%;
    width: fit-content;
    transform: translateX(100%);
  }
  
  .drawer-top {
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    transform: translateY(-100%);
  }
  
  .drawer-bottom {
    bottom: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    transform: translateY(100%);
    background: var($addressDrawerBackground);
  }
  
  .drawer.open.drawer-left {
    transform: translateX(0);
  }
  
  .drawer.open.drawer-right {
    transform: translateX(0);
  }
  
  .drawer.open.drawer-top {
    transform: translateY(0);
  }
  
  .drawer.open.drawer-bottom {
    transform: translateY(0);
  }
  