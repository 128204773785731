.user-avatar-container {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var($quaternaryBackground);
  display: flex;
  justify-content: center;
  align-items: center;
  .profile-image {
    width: 100%;
    height: 100%;
    background-color: var($tertiaryBackground);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: var($quaternaryBackground);
   
  }
  .profile-image-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}
