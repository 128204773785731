.cart-item-container {
  width: 93.5vw;
  justify-content: space-between;
  display: flex;
  padding: 0px 10px;
  border-bottom: 1px solid rgb(239, 233, 233);
  margin-left: -2px;
  .cart-item-info {
    width: 50vw;
    display: flex;
    flex-direction: column;
    padding: 10px;
    flex-wrap: wrap;

    .cart-item-mrp {
      font-size: 10px;
      color: var($errorTextColor);
      margin-bottom: 10px;
    }
    .cart-item-name {
      font-size: 18px;
      margin-bottom: 2px;
      text-align: start;
    }
    .cart-item-sp {
      font-size: 17px;
      font-weight: 800;
      color: var($primaryTextColor);
    }
    .cart-item-item-total {
      font-size: 16px;
      font-weight: bold;
      margin-top: 1rem;
    }
  }
  .cart-item-quantity {
    width: 40vw;
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .image-container {
      width: 30vw;
      height: 15vh;
      display: flex;
      border-radius: 20px;
      justify-content: center;
      .cart-item-image {
        width: 100%;
        border-radius: 20px;
      }
    }
  }
}
.cart-product-container {
  height: fit-content;
  box-sizing: border-box;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 12px; 
}

.cart-item-product {
  display: flex;
  padding: 8px 10px;
  align-self: stretch;
  gap: 12px; 
}
.cart-item-remove {
  // width: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.cart-product-image {
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, var($cartImageBackground) 0%, var($secondaryColor) 10%, var($cartImageBackground)  40%, var($cartImageBackground)  100%) no-repeat var($cartImageBackground) ;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.cart-image-product {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  position: absolute;
}

.cart-product-content {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  flex-grow: 1; 
}

.cart-product-content-text {
  flex: 1; 
}

.cart-product-content-button {
  display: flex;
  align-items: center;
  margin-top: 20px ;
}
.cart-item-name{
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.cart-item-mrp-price{
  font-size: 12px;
  line-height: 16px;
  font-weight: 800;
}