.account-deletion-container {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.account-deletion-container h2,
.account-deletion-container h3 {
  color: #333;
}

.account-deletion-container p {
  color: #555;
  line-height: 1.6;
}

.deletion-steps {
  margin: 10px 0;
  padding-left: 20px;
}

.deletion-steps li {
  margin: 5px 0;
}

.deletion-link {
  display: inline-block;
  margin: 20px 0;
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  &:hover {
    background-color: #0056b3;
  }
}


.data-retention-info ul {
  padding-left: 20px;
}

.data-retention-info ul li {
  margin: 5px 0;
}

.data-retention-info a {
  color: #007bff;
}

.data-retention-info a:hover {
  text-decoration: underline;
}
